<template>
  <VDialog
    max-width="800px"
    content-class="cross__dialog"
    :transition="$dialogTransition"
    v-model="visibility"
  >
    <VCard class="px-1 py-1">
      <div class="cross__inner">
        <VBtn fab small elevation="0" @click="onClose()">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <div>
        <VRow class="mt-2 text-center" justify="center">
          <VCol cols="12" class="font-weight-medium text-h6">{{ $t("crm.statsTitle") }}</VCol>
        </VRow>
        <EchartVue
          :type="'month'"
          :chartData="datacollection"
          :isLoading="loading"
          class="mx-1 mt-3"
        />
        <div class="text-right font-weight-regular text-caption">Загалом: {{ totalCounter }}</div>
      </div>
    </VCard>
  </VDialog>
</template>

<script>
import EchartVue from "@/components/charts/Echart.vue";
import loader from "@/mixins/loader";
import user from "@/mixins/user";
import joinRequestService from "@/services/request/joinRequest/joinRequestService";
import { mapGetters } from "vuex";

export default {
  name: "JoinRequestsChart",
  mixins: [loader, user],
  components: {
    EchartVue
  },
  props: {
    visible: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    labels: [],
    datasets: [],
    datacollection: null,
    loading: false,
    totalCounter: 0
  }),
  mounted() {
    this.createGraphic();
  },
  methods: {
    onClose() {
      this.visibility = false;
    },
    getStatusText(item) {
      return item[`${this.currentLanguage.key}_name`];
    },
    async createGraphic() {
      try {
        this.loading = true;
        const itemsDefault = [
          {
            name: "New",
            uk_name: "Новий",
            color: "#4894d9",
            amount: 0
          },
          {
            name: "In Progress",
            uk_name: "В Процесі",
            color: "#caab62",
            amount: 0
          },
          {
            name: "Blocked",
            uk_name: "Заблоковано",
            color: "#ca6285",
            amount: 0
          },
          {
            name: "Success",
            uk_name: "Успіх",
            color: "#65bc54",
            amount: 0
          },
          {
            name: "Decline",
            uk_name: "Відхилено",
            color: "#c05f5f",
            amount: 0
          }
        ];
        const requestMetrics = await joinRequestService.getRequestMetrics();
        requestMetrics?.forEach(item => {
          if (item.name) {
            const foundItem = itemsDefault.find(itemDefault => itemDefault.name === item.name);
            if (foundItem) {
              foundItem.amount = item.amount;
            }
          }
        });
        const labels = [];
        itemsDefault.forEach(item => {
          labels.push(this.currentLanguage.key == "uk" ? item.uk_name : item.name);
        });
        const data = itemsDefault.map(value => ({
          value: value.amount,
          itemStyle: {
            color: value.color
          }
        }));
        this.datacollection = {
          labels: [...labels],
          datasets: [
            {
              backgroundColor: "#4fae3b1f",
              borderColor: "#4fae3b",
              data: [...data]
            }
          ]
        };
        this.totalCounter = data?.reduce((total, item) => total + parseInt(item.value, 10), 0) || 0;
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      }
    },
    ...mapGetters(["currentLanguage"])
  }
};
</script>
