<template>
  <tr>
    <th class="icon text-center"><VIcon dense>mdi-cogs</VIcon></th>
    <th class="text-left">
      <div class="header">
        <VTextField
          @keypress="validationForName($event)"
          @paste="validationForName($event)"
          :placeholder="$t('table.name')"
          dense
          hide-details
          solo
          v-model="filter.name"
          clearable
        />
      </div>
    </th>
    <th class="text-left">
      <div class="header">
        <VTextField
          @keypress="validationForPhoneNumber($event)"
          @paste="validationForPhoneNumber($event)"
          :placeholder="$t('table.phone')"
          dense
          hide-details
          autocomplete="new-password"
          solo
          v-model="filter.phone"
          clearable
        />
      </div>
    </th>
    <th>
      <div class="header">
        <VTextField
          @keypress="validationForEmail($event)"
          @paste="validationForEmail($event)"
          :placeholder="$t('table.email')"
          dense
          hide-details
          solo
          autocomplete="new-password"
          v-model="filter.email"
          clearable
        />
      </div>
    </th>
    <th>
      <div class="header">
        <VSelect
          dense
          :placeholder="$t('table.status')"
          :items="Object.values(states)"
          :item-text="getStatusText"
          :item-value="'id'"
          hide-details
          solo
          v-model="filter.id_state"
          class="text-caption"
          clearable
        >
          <template v-slot:item="{ item }">
            <div class="d-flex align-center justify-content-start ">
              <StatusCircle :status-name="item.id" :type="'supply'" class="pb-1" />
              <div class="elispsis">
                {{ getStatusText(item) }}
              </div>
            </div>
          </template>
          <template v-slot:selection="{ item }">
            <div class="d-flex align-center justify-content-start ">
              <StatusCircle :status-name="item.id" :type="'supply'" class="pb-1" />
              <div class="elispsis">
                {{ getStatusText(item) }}
              </div>
            </div>
          </template>
        </VSelect>
      </div>
    </th>
    <th>
      <div class="header">
        <VSelect
          dense
          :placeholder="$t('table.state')"
          :items="statusOptions"
          item-text="text"
          item-value="value"
          hide-details
          solo
          v-model="filter.status"
          class="text-caption"
          clearable
        >
          <template v-slot:item="{ item }">
            <div class="d-flex align-center justify-content-start ">
              <div :class="getStatusColorClass(item.value)" class="status-marker"></div>
              <div class="elispsis">
                {{ item.text }}
              </div>
            </div>
          </template>
          <template v-slot:selection="{ item }">
            <div class="d-flex align-center justify-content-start ">
              <div :class="getStatusColorClass(item.value)" class="status-marker"></div>
              <div class="elispsis">
                {{ item.text }}
              </div>
            </div>
          </template>
        </VSelect>
      </div>
    </th>
    <th class="text-left ">
      <div class="header mt-4">
        <v-menu
          v-model="dateMenuUpdated"
          :close-on-content-click="false"
          :nudge-right="-103"
          transition="scale-transition"
          offset-y
          min-width="auto"
          class="text-caption"
        >
          <template v-slot:activator="{ on, attrs }">
            <VTextField
              class="bg-white"
              v-model="filter.time_updated"
              :placeholder="$t('table.updatedData')"
              readonly
              append-icon="mdi-calendar"
              solo
              dense
              hide-details
              v-bind="attrs"
              v-on="on"
              clearable
            />
          </template>
          <v-date-picker
            :locale="currentLanguage.key"
            no-title
            :max="NextYear"
            min="2021"
            v-model="filter.time_updated"
            @input="dateMenuUpdated = false"
          ></v-date-picker>
        </v-menu>
      </div>
    </th>
    <th class="text-left ">
      <div class="header mt-4">
        <v-menu
          v-model="dateMenuLastContact"
          :close-on-content-click="false"
          :nudge-right="-103"
          transition="scale-transition"
          offset-y
          min-width="auto"
          class="text-caption"
        >
          <template v-slot:activator="{ on, attrs }">
            <VTextField
              class="bg-white"
              v-model="filter.time_last_contact"
              :placeholder="$t('form.time_last_contact')"
              readonly
              append-icon="mdi-calendar"
              solo
              dense
              hide-details
              v-bind="attrs"
              v-on="on"
              clearable
            />
          </template>
          <v-date-picker
            :locale="currentLanguage.key"
            no-title
            :max="NextYear"
            min="2021"
            v-model="filter.time_last_contact"
            @input="dateMenuLastContact = false"
          ></v-date-picker>
        </v-menu>
      </div>
    </th>
    <th class="text-left ">
      <div class="header mt-4">
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          :nudge-right="-103"
          transition="scale-transition"
          offset-y
          min-width="auto"
          class="text-caption"
        >
          <template v-slot:activator="{ on, attrs }">
            <VTextField
              class="bg-white"
              v-model="filter.time_created"
              :placeholder="$t('table.createdData')"
              readonly
              append-icon="mdi-calendar"
              solo
              dense
              hide-details
              v-bind="attrs"
              v-on="on"
              clearable
            />
          </template>
          <v-date-picker
            :locale="currentLanguage.key"
            no-title
            :max="NextYear"
            min="2021"
            v-model="filter.time_created"
            @input="dateMenu = false"
          ></v-date-picker>
        </v-menu>
      </div>
    </th>
    <th>
      <div class="header">
        <VTextField
          v-on:keypress="validationForTitle($event)"
          @paste="validationForTitle($event)"
          :placeholder="$t('table.created')"
          dense
          hide-details
          solo
          v-model="filter.user_details__name"
          clearable
        />
      </div>
    </th>
  </tr>
</template>

<script>
import {
  validationForEmail, 
  validationForName, 
  validationForPhoneNumber, 
  validationForTitle
} from "@/mixins/helpers";
import { mapGetters } from "vuex";
import StatusCircle from "@/components/move-list/StatusCircle.vue";

export default {
  methods: {
    validationForTitle,
    validationForEmail,
    validationForPhoneNumber,
    validationForName,
    getStatusText(item) {
      return item[`${this.currentLanguage.key}_name`];
    },
    getStatusColorClass(status) {
      switch (status) {
        case 'HOT':
          return 'hot';
        case 'WARM':
          return 'warm';
        case 'COLD':
          return 'cold';
        default:
          return '';
      }
    },
  },
  props: {
    filter: {
      required: true
    },
    states: {
      required: true
    }
  },
  data: () => ({
    dateMenu: false,
    dateMenuUpdated:false,
    dateMenuLastContact:false,
    statusOptions: [
      { text: 'HOT', value: 'HOT' },
      { text: 'WARM', value: 'WARM' },
      { text: 'COLD', value: 'COLD' },
    ],
  }),
  components: {
    StatusCircle
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    NextYear() {
      const event = new Date().toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).substr(0, 10);

      const [month, day, year] = event.split(/[\s,\/]+/);
      const formattedEvent = `${year}-${month}-${day}`;
      return formattedEvent;
    }
  }
};
</script>

<style></style>
